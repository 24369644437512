import {
  Button,
  CircularProgress,
  InputAdornment,
  Pagination,
  TextField,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState, useCallback, useRef } from "react";
import { AiOutlineSearch, AiOutlineDownload } from "react-icons/ai";
import { IoMdTrash } from "react-icons/io";
import DatePicker from "react-datepicker";
import {
  getPdfPo,
  deletePurchaseOrder,
  getPoListCW,
} from "../../../services/purchaseOrderApi";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyList from "../../ui/EmptyList";
import useDebounce from "../../hooks/useDebounce";
import { BsPrinter } from "react-icons/bs";
import { useReactToPrint } from "react-to-print";
import PrintNota from "../../common/particles.jsx/PrintNota";
import { getPreviewInvoice } from "../../../services/ReportingApi";

const FETCH_LIMIT = 10;

const toApiDate = (date) => moment(date).format("YYYY-MM-DD");
const toDate = (date) => moment(date).format("DD MMM YYYY");

const PurchaseOrderList = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const printRef = useRef();
  const location = useLocation();

  const [offset, setOffset] = useState(1);
  const [searchStatus, setSearchStatus] = useState(0);
  const [selectedPo, setSelectedPo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [poId, setPoId] = useState(0);

  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [period, setPeriod] = useState(0);
  const [periodDummy, setPeriodDummy] = useState(0);
  const [resBody, setResBody] = useState({
    warehouse: isWarehouse ? isWarehouse : false,
    search: "",
    limit: FETCH_LIMIT,
    offset,
    type: 0,
    sort: 0,
    start_date: "",
    end_date: "",
  });

  const debounce = useDebounce(resBody.search, 500);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setSelectedPo(null),
  });

  const {
    mutate: mutatePoList,
    data,
    isLoading,
  } = useMutation({
    mutationFn: getPoListCW,
  });

  const { data: dataPdf, isFetching: fetchingPdf } = useQuery({
    queryKey: ["po-pdf", selectedPo],
    refetchInterval: 5000,
    queryFn: () => getPdfPo(selectedPo.id, isWarehouse ? isWarehouse : false),
    onSuccess: () => {
      setTimeout(() => {
        handlePrint();
      }, 500);
    },
    enabled: selectedPo !== null,
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  const NotaInfo = () => {
    return (
      <div className="mb-4">
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Nama Supplier</p>
            <p>:</p>
            <p>{dataPdf.distributor_name}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Tanggal</p>
            <p>:</p>
            <p>{moment(dataPdf.created_at).format("DD MMMM YYYY")}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No. Telepon</p>
            <p>:</p>
            <p>{dataPdf.distributor_phone}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No. PO</p>
            <p>:</p>
            <p>{dataPdf.sp_no}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Alamat</p>
            <p>:</p>
            <p>{dataPdf.distributor_address}</p>
          </div>
        </div>
      </div>
    );
  };

  const { mutate: mutateDeletePo, isLoading: isMutateDeletePoLoading } =
    useMutation({
      mutationFn: deletePurchaseOrder,
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        setPoId(0);
        mutatePoList({
          warehouse: isWarehouse ? isWarehouse : false,
          search: debounce,
          limit: FETCH_LIMIT,
          offset,
          type: searchStatus,
          sort: period,
          start_date: tanggalMulai,
          end_date: tanggalAkhir,
        });
      },
    });

  // useEffect(() => {
  //   if (dataPdf !== null && isShare && selectedPo !== null)
  //     downloadPDF(dataPdf);
  // }, [dataPdf, isShare]);

  useEffect(() => {
    mutatePoList(resBody);
  }, [resBody, mutatePoList, location.pathname]);

  return (
    <div>
      <div className="flex w-full gap-2 items-center my-4">
        <TextField
          value={resBody.search}
          label={
            resBody.search === "" ? "" : "No. PO/Nama Distributor/Nama Obat"
          }
          placeholder={
            resBody.search !== "" ? "" : "No. PO/Nama Distributor/Nama Obat"
          }
          fullWidth
          onChange={(e) =>
            setResBody((prevValue) => ({
              ...prevValue,
              search: e.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Periode PO</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="category_id"
            label="Periode PO"
            value={periodDummy}
            onChange={(e) => {
              if (e.target.value === 9) {
                setOpenCalendar(true);
                return;
              } else if (e.target.value !== 10) {
                setOpenCalendar(false);
                setStartDate(null);
                setEndDate(null);
                setTanggalMulai("");
                setTanggalAkhir("");
              }
              setPeriod(e.target.value);
              setResBody((prevValue) => ({
                ...prevValue,
                sort: e.target.value,
              }));
              setPeriodDummy(e.target.value);
            }}
          >
            <MenuItem value={0}>Semua</MenuItem>
            <MenuItem value={1}>Hari ini</MenuItem>
            <MenuItem value={2}>Kemarin</MenuItem>
            <MenuItem value={4}>Minggu Berjalan</MenuItem>
            <MenuItem value={3}>Minggu Lalu</MenuItem>
            <MenuItem value={5}>Bulan Berjalan</MenuItem>
            <MenuItem value={6}>Bulan Lalu</MenuItem>
            <MenuItem value={7}>Tahun Berjalan</MenuItem>
            <MenuItem value={8}>Tahun Lalu</MenuItem>
            <MenuItem value={9}>Pilih Tanggal</MenuItem>
            <MenuItem value={10} disabled sx={{ display: "none" }}>
              {tanggalMulai + " - " + tanggalAkhir}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            value={resBody.type}
            onChange={(e) => {
              // setSearchStatus(e.target.value);
              setResBody((prevValue) => ({
                ...prevValue,
                type: e.target.value,
              }));
            }}
          >
            <MenuItem value={0}>Semua PO</MenuItem>
            <MenuItem value={1}>Not Invoiced</MenuItem>
            <MenuItem value={2}>Invoiced</MenuItem>
            <MenuItem value={3}>Partial</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          fullWidth
          type="button"
          sx={{ height: "100%", flexBasis: "50%" }}
          onClick={() =>
            navigate(
              `${
                isWarehouse ? "/central-warehouse" : ""
              }/purchase-order/create-po`
            )
          }
        >
          Create PO
        </Button>
      </div>

      {data && data !== undefined ? (
        <div>
          <p className="font-bold text-xl mb-4">List of Purchase Order</p>
          <div className="block overflow-x-scroll">
            <table className="w-full border">
              <thead className="bg-blue-500 text-white text-center">
                <tr>
                  <th className="py-2 border">Tanggal PO</th>
                  <th className="py-2 border">Nomor PO</th>
                  <th className="py-2 border">Distributor</th>
                  <th className="py-2 border">Invoice</th>
                  <th className="py-2 border-t border-l border-r">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {data.data !== null && data.data.length > 0 ? (
                  <>
                    {data.data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="py-2 border">
                            {moment(item.po_date).format("DD MMMM YYYY")}
                          </td>
                          <td
                            className="py-2 border text-blue-500 underline cursor-pointer hover:text-blue-700"
                            onClick={() => {
                              navigate(
                                `${
                                  isWarehouse ? "/central-warehouse" : ""
                                }/purchase-order/detail-po/${btoa(item.id)}-${
                                  item.is_partial ? 1 : 0
                                }`
                              );
                            }}
                          >
                            {item.po_number}
                          </td>
                          <td className="py-2 border">
                            {item.distributor_name}
                          </td>
                          <td
                            className={`py-2 border ${
                              item.get_purchase_order_billing_lists
                                ? "text-blue-500 underline hover:text-blue-700 cursor-pointer p-2"
                                : ""
                            }`}
                          >
                            {item.get_purchase_order_billing_lists
                              ? item.get_purchase_order_billing_lists.map(
                                  (bill, billIndex) => (
                                    <p
                                      key={billIndex}
                                      onClick={() =>
                                        navigate(
                                          `${
                                            isWarehouse
                                              ? "/central-warehouse"
                                              : ""
                                          }/manual-billing/detail/${
                                            bill.billing_id
                                          }`
                                        )
                                      }
                                    >
                                      {bill.invoice}
                                      {billIndex <
                                      item.get_purchase_order_billing_lists
                                        .length -
                                        1
                                        ? ","
                                        : ""}
                                    </p>
                                  )
                                )
                              : "-"}
                          </td>
                          <td className="py-2 border-t h-full flex justify-center items-center gap-1">
                            <button
                              className="bg-[#395B7D] rounded-md p-2 cursor-pointer hover:bg-slate-500 w-8 h-8 flex items-center justify-center"
                              onClick={() => {
                                setSelectedPo(item);
                              }}
                            >
                              {fetchingPdf &&
                              selectedPo.po_number === item.po_number ? (
                                <CircularProgress
                                  size={20}
                                  className="text-white"
                                />
                              ) : (
                                <BsPrinter className="text-white" />
                              )}
                            </button>
                            {item.is_delete ? (
                              <button
                                className="bg-red-500 rounded-md p-2 cursor-pointer hover:bg-slate-500 w-8 h-8"
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setPoId(item.id);
                                }}
                              >
                                {<IoMdTrash className="text-white" />}
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : data ? (
        <div className="mt-12 flex justify-center flex-col">
          <EmptyList message="Belum ada Purchase Order list saat ini" />
          <div className="w-full flex justify-center">
            <Button
              variant="contained"
              sx={{ paddingX: 8 }}
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/purchase-order/create-po`
                )
              }
            >
              Create PO
            </Button>
          </div>
        </div>
      ) : isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <p className="text-red-500">An Error Occured</p>
      )}

      <div className="flex justify-end mt-4">
        {data && data.data !== null && data.data.length > 0 ? (
          <Pagination
            count={Math.ceil(data.total / FETCH_LIMIT)}
            page={resBody.offset}
            onChange={(_, value) =>
              setResBody((prevValue) => ({
                ...prevValue,
                offset: value,
              }))
            }
            shape="rounded"
            size="large"
            color="primary"
          />
        ) : null}
      </div>
      <Dialog
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent>
          <div className="flex">
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 font-semibold w-full"
            onClick={() => {
              mutatePoList({
                warehouse: isWarehouse ? isWarehouse : false,
                search: debounce,
                limit: FETCH_LIMIT,
                offset,
                type: searchStatus,
                sort: 9,
                start_date: toApiDate(startDate),
                end_date: toApiDate(endDate),
              });
              setTanggalMulai(toDate(startDate));
              setTanggalAkhir(toDate(endDate));
              setOpenCalendar(false);
              setPeriod(9);
              setPeriodDummy(10);
            }}
          >
            Konfirmasi
          </button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <DialogTitle>Anda yakin ingin menghapus PO ini?</DialogTitle>
        <DialogContent>
          <button
            className="bg-red-500 py-2 rounded-md text-white w-full font-bold mb-2"
            disabled={isMutateDeletePoLoading}
            onClick={() => {
              mutateDeletePo(poId);
            }}
          >
            {isMutateDeletePoLoading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <p>Delete</p>
            )}
          </button>
          <button
            className="border border-black py-2 rounded-md w-full"
            disabled={isMutateDeletePoLoading}
            onClick={() => {
              setIsDeleteModalOpen(false);
              setPoId(0);
            }}
          >
            Cancel
          </button>
        </DialogContent>
      </Dialog>

      <div className="hidden">
        <PrintNota
          dataLabel={dataLabel}
          data={dataPdf}
          dataList={dataPdf ? dataPdf.product : []}
          printRef={printRef}
          title="Surat Pesanan"
          tableContent={[
            {
              header: "No",
              key: "INDEX",
            },
            {
              header: "Nama Obat",
              key: "product_name",
            },
            {
              header: "Qty",
              key: "product_quantity",
            },
            {
              header: "Satuan",
              key: "product_unit",
            },
          ]}
          totalPrice={null}
          NotaInfo={NotaInfo}
          email={dataPdf ? dataPdf.pharmacy.pharmacy_email : "-"}
        />
      </div>
    </div>
  );
};

export default PurchaseOrderList;
