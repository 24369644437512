import { Box, CircularProgress, Modal } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { BsTrash } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { deleteDistributor } from "../../../../services/purchaseOrderApi";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalDeleteDistributor = ({ open, setOpen, dist, setDist, refetch }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    setDist(null);
  };

  const { mutate: mutateDelete, isLoading } = useMutation({
    mutationFn: deleteDistributor,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (err) => setErrorMessage(err.message.id),
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "50vh",
          padding: 0,
        }}
        className="text-left text-[14px] w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          <div className="w-12 h-12 bg-red-100 p-1 rounded-full">
            <div className="w-full h-full bg-red-300 p-2 rounded-full">
              <BsTrash className="w-full h-full text-red-600" />
            </div>
          </div>
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg ">Hapus Distributor</p>
          <p>Apakah anda yakin ingin menghapus distributor ini?</p>
          <p className="mt-2 text-red-500">{errorMessage}</p>
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-red-500 bg-red-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
            onClick={() => mutateDelete(dist?.id)}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={10} /> : "Delete"}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalDeleteDistributor;
