import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { RxCross1 } from "react-icons/rx";
import * as yup from "yup";
import {
  setNewDistributor,
  updateDistributor,
} from "../../../../services/purchaseOrderApi";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validationSchema = yup.object({
  name: yup.string().required(),
  phone: yup.string().required(),
  address: yup.string().required(),
  email: yup.string().required(),
});

const ModalAddDistributor = ({ open, setOpen, id, setId, refetch }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    if (id) setId(false);
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: id ? id.name : "",
      phone: id ? id.phone : "",
      address: id ? id.address : "",
      email: id ? id.email : "",
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      id
        ? mutateUpdateDistributor({ ...values, id: Number(id.id) })
        : mutateNewDistributor(values);
    },
  });

  const { mutate: mutateNewDistributor, isLoading: isLoadingNew } = useMutation(
    {
      mutationFn: setNewDistributor,
      onSuccess: () => {
        handleClose();
      },
      onError: (err) => setErrorMessage(err.message.id),
    }
  );

  const { mutate: mutateUpdateDistributor, isLoading: isLoadingUpdate } =
    useMutation({
      mutationFn: updateDistributor,
      onSuccess: () => {
        refetch();
        handleClose();
      },
      onError: (err) => setErrorMessage(err.message.id),
    });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/3"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex items-center px-8 py-4 w-full text-center">
            <p className="w-full text-[20px] font-bold">Tambah Distributor</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit"
              onClick={handleClose}
            />
          </div>

          <hr className="border-b-2 mb-4" />

          <div className="px-8 mb-4">
            <TextField
              fullWidth
              label={
                <p>
                  Nama Distributor <span className="text-red-500">*</span>
                </p>
              }
              sx={{ marginBottom: 2 }}
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue("name", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  Email <span className="text-red-500">*</span>
                </p>
              }
              sx={{ marginBottom: 2 }}
              value={formik.values.email}
              onChange={(e) => {
                formik.setFieldValue("email", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  Alamat <span className="text-red-500">*</span>
                </p>
              }
              multiline
              rows={4}
              sx={{ marginBottom: 2 }}
              value={formik.values.address}
              onChange={(e) => {
                formik.setFieldValue("address", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  No Telepon <span className="text-red-500">*</span>
                </p>
              }
              value={formik.values.phone}
              onChange={(e) => {
                if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                  formik.setFieldValue("phone", e.target.value);
                  setErrorMessage("");
                }
              }}
            />
            <p className="mt-2 text-red-500">{errorMessage}</p>
          </div>

          <hr className="border-b-2 mb-4" />

          <div className="px-8 mb-4 flex gap-2 items-center">
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!formik.isValid || isLoadingNew || isLoadingUpdate}
              className="w-full p-2 rounded-md bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
            >
              {isLoadingNew || isLoadingUpdate ? (
                <CircularProgress size={10} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalAddDistributor;
