import moment from "moment";

const PrescriptionPreview = ({ formik, selectedDoctor, selectedPatient }) => {
  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  function romanize(num) {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }

  const filterRegular = () => {
    return formik.values.regular.filter(
      (item) => item.product_name !== "" && item.uom !== "" && item.quantity > 0
    );
  };

  const filterConcoction = () => {
    return formik.values.concoction.filter((conc) =>
      conc.product.every(
        (prod) =>
          prod.product_name !== "" && prod.uom !== "" && prod.quantity > 0
      )
    );
  };

  return (
    <>
      {selectedDoctor ? (
        <div className="w-full font-serif">
          <p className="font-bold text-xl mb-2">{selectedDoctor.name}</p>

          <div className="block">
            <p className="inline-block w-[100px]">No SIP</p>
            <p className="inline-block">
              : {selectedDoctor.sip === "" ? "-" : selectedDoctor.sip}
            </p>{" "}
          </div>
          <div className="block">
            <p className="inline-block w-[100px]">Alamat</p>
            <p className="inline-block">
              : {selectedDoctor.address === "" ? "-" : selectedDoctor.address}
            </p>
          </div>
          <div className="block">
            <p className="inline-block w-[100px]">No Telepon</p>
            <p className="inline-block">
              : {selectedDoctor.phone === "" ? "-" : selectedDoctor.phone}
            </p>
          </div>
          <hr className="my-4 border-black border-t-5" />
          <p className="w-full text-right mb-4">
            {moment(formik.values.prescription_date).format("DD MMMM YYYY")}
          </p>

          <div className="w-full mb-8">
            {filterRegular().map((item, i) => (
              <div className="mb-4">
                <div className="flex gap-4 w-full">
                  <p>R/</p>
                  <div className="w-full">
                    <p>
                      -{item.product_name} {item.uom} {romanize(item.quantity)}
                    </p>
                    <p>S. {item.signa}</p>
                  </div>
                  <div className="w-fit whitespace-nowrap ">
                    <p className="border-blue-500 border-2 p-2 text-blue-500">
                      Iter {item.iter}x
                    </p>
                  </div>
                </div>
                <hr className="my-2 border-black border-t-5" />
              </div>
            ))}
          </div>

          <div className="w-full mb-4">
            {filterConcoction().map((item, i) => (
              <div className="mb-4">
                <p className="font-bold mb-2">Racikan {i + 1}</p>
                <div className="flex gap-4 w-full">
                  <p>R/</p>
                  <div className="w-full">
                    {item.product.map((prod, prodIndex) => (
                      <p>
                        -{prod.product_name} {prod.uom}{" "}
                        {romanize(prod.quantity)}
                      </p>
                    ))}
                    <p>m.f {item.misce_fiat}</p>
                    <p>S. {item.signa}</p>
                  </div>
                  <div className="w-fit whitespace-nowrap ">
                    <p className="border-blue-500 border-2 p-2 text-blue-500">
                      Iter {item.iter}x
                    </p>
                  </div>
                </div>
                <hr className="my-2 border-black border-t-5" />
              </div>
            ))}
          </div>

          <div className="block">
            <p className="inline-block w-[100px]">Pro</p>
            <p className="inline-block">: {selectedPatient.patient_name}</p>
          </div>
          <div className="block">
            <p className="inline-block w-[100px]">Umur</p>
            <p className="inline-block">
              : {getAge(selectedPatient.date_of_birth)} Tahun
              {formik.values.objective.weight &&
              formik.values.objective.weight !== ""
                ? "(" + formik.values.objective.weight + " kg)"
                : null}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-gray-300">Buat resep untuk melihat preview</p>
      )}
    </>
  );
};

export default PrescriptionPreview;
