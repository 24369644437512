import {
  EXCLUDE_BASIC_PACKAGE,
  EXCLUDE_PREMIUM_PACKAGE,
  SIDEBAR_WIDTH,
} from "../../constants/constant";
import Header from "../../ui/Header";
import SidebarComponents from "../../ui/SidebarComponents";
import NotFound from "../../../assets/images/not_found.png";

/**
  Component to wrap every page that has a header and sidebar
*/

const PageTemplate = ({
  header,
  breadcrumb,
  mainComponent,
  pageRole,
  noPadding,
}) => {
  const packageStatus = localStorage.getItem("package");
  const roles = localStorage.getItem("roles");

  const isIncludePackage = () => {
    if (Number(packageStatus) === 1 && EXCLUDE_BASIC_PACKAGE.includes(pageRole))
      return false;
    if (
      Number(packageStatus) === 2 &&
      EXCLUDE_PREMIUM_PACKAGE.includes(pageRole)
    )
      return false;
    return true;
  };

  const userHasAccess = () => {
    // has access if user's package status has it & included in its own list of roles
    // TODO: add access for reporting children, discount, pelanggan for exclude basic package
    if (pageRole === undefined || pageRole === null) return false;
    if (!isIncludePackage()) return false;
    if (pageRole === 0) return true;

    return roles.includes(pageRole);
  };

  return (
    <div className="">
      <div className="flex">
        <SidebarComponents />
        <div
          className={`w-full lg:w-[calc(100%-${SIDEBAR_WIDTH}px)] overflow-hidden`}
        >
          <div className={`p-3 pb-0`}>
            <Header />
            {header ? (
              <h1 className="font-semibold text-[20px] lg:text-[24px] mb-2">
                {header}
              </h1>
            ) : null}
            {breadcrumb ? (
              <div className="mb-4 hidden lg:block">{breadcrumb}</div>
            ) : null}
          </div>
          <div className={`${noPadding ? "" : "p-3"}`}>
            {userHasAccess() ? (
              <div className="mt-4 lg:mt-0">{mainComponent} </div>
            ) : (
              <div className="flex flex-col items-center justify-center pb-4 h-full min-h-[50vh] ">
                <div>
                  <img src={NotFound} className="w-[150px]" />
                </div>
                <p className="text-xl align-center mt-8">
                  Anda belum memiliki akses ke fitur ini
                </p>
                <p className="text-gray-400">
                  {isIncludePackage()
                    ? "Silahkan hubungi owner"
                    : "Silahkan upgrade subscription OLIN anda"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;
