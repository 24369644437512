import React from "react";
import CreatePurchaseOrder from "../../components/screen/purchaseOrderCW/CreatePurchaseOrder";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PurchaseOrderCW = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header=""
        mainComponent={<CreatePurchaseOrder isWarehouse={isWarehouse} />}
        pageRole={29}
      />
    </div>
  );
};

export default HOC(PurchaseOrderCW);
