import React from "react";
import PurchaseOrderList from "../../components/screen/purchaseOrder/PurchaseOrderList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbPurchaseOrder from "../../components/screen/purchaseOrder/BreadcrumbPurchaseOrder";

const PurchaseOrder = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Purchase Order Manual"}
        breadcrumb={<BreadcrumbPurchaseOrder />}
        mainComponent={
          <ThemeProvider theme={theme}>
            <PurchaseOrderList />
          </ThemeProvider>
        }
        pageRole={9}
      />
    </div>
  );
};

export default HOC(PurchaseOrder);
