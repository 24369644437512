import React from "react";
import PurchaseOrderList from "../../components/screen/purchaseOrderCW/PurchaseOrderList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";

const PurchaseOrderCW = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header="Central Warehouse Purchase Order"
        mainComponent={<PurchaseOrderList isWarehouse={isWarehouse} />}
        pageRole={29}
      />
    </div>
  );
};

export default HOC(PurchaseOrderCW);
