import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbPurchaseOrder = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2, fontSize: 12 }}>
      <Link
        href="/home"
        underline="hover"
        color="#1761FD"
        sx={{ color: "#1761FD" }}
      >
        Homepage
      </Link>
      <Link
        color="#1761FD"
        underline="none"
        href="/purchase-order"
        sx={{ color: "#1761FD" }}
      >
        Purchase Order Manual
      </Link>
      {pathname.includes("/purchase-order/create-po") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/purchase-order/create-po"
          sx={{ color: "#1761FD" }}
        >
          Create PO
        </Link>
      ) : null}
      {pathname.includes("/purchase-order/detail-invoice/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Invoice
        </Link>
      ) : null}
      {pathname.includes("/purchase-order/detail-po/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail PO
        </Link>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbPurchaseOrder;
