import React from "react";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import BreadcrumbPurchaseOrder from "../../components/screen/purchaseOrder/BreadcrumbPurchaseOrder";
import AddPurchaseOrder from "../../components/screen/purchaseOrder/AddPurchaseOrder";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";

const PurchaseOrder = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Add Purchase Order Manual"}
        breadcrumb={<BreadcrumbPurchaseOrder />}
        mainComponent={
          <ThemeProvider theme={theme}>
            <AddPurchaseOrder />
          </ThemeProvider>
        }
        pageRole={9}
        noPadding={true}
      />
    </div>
  );
};

export default HOC(PurchaseOrder);
