import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import BreadcrumbPurchaseOrder from "../../components/screen/purchaseOrder/BreadcrumbPurchaseOrder";
import DetailBillingManual from "../../components/screen/billingManual/DetailBillingManual";

const DetailInvoicePurchaseOrderPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Purchase Order Manual"}
        mainComponent={<DetailBillingManual isWarehouse={false} />}
        breadcrumb={<BreadcrumbPurchaseOrder />}
        pageRole={9}
      />
    </div>
  );
};

export default HOC(DetailInvoicePurchaseOrderPage);
