import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Popper,
  Select,
  TextField,
} from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import moment from "moment";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";
import { useEffect, useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { getPoList } from "../../../services/purchaseOrderApi";
import { useMutation } from "@tanstack/react-query";
import PagePagination from "../../common/particles.jsx/PagePagination";
import { useNavigate } from "react-router-dom";
import ModalDeletePo from "./modal/ModalDeletePo";

const INITIAL_REQ = {
  search: "",
  limit: 10,
  offset: 1,
  type: 0,
  sort: 0,
  start_date: "",
  end_date: "",
};

const PurchaseOrderList = () => {
  const navigate = useNavigate();

  const [anchorElCal, setAnchorElCal] = useState(null);
  const [anchorElMoreProduct, setAnchorElMoreProduct] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedPo, setSelectedPo] = useState(null);
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [moreProductArr, setMoreProductArr] = useState([]);

  const {
    mutate: mutatePoList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  useEffect(() => {
    mutatePoList(reqBody);
  }, [reqBody]);

  return (
    <div className="text-[12px]">
      <div className="flex w-full gap-[10px] items-center mb-4">
        <TextField
          fullWidth
          placeholder="Cari Data"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div
          className="w-1/2 p-2 rounded-md border-2 flex gap-2  justify-between items-center text-gray-500 hover:bg-gray-100 cursor-pointer text-sm"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <IoCalendarOutline />
          <p className="whitespace-nowrap">
            {(reqBody.start_date === "") & (reqBody.end_date === "")
              ? "No Date Choosen"
              : moment(reqBody.start_date).format("DD MMM YYYY") +
                "-" +
                moment(reqBody.end_date).format("DD MMM YYYY")}
          </p>
          <FaChevronDown />
        </div>
        <Select value={0} sx={{ width: "50%" }}>
          <MenuItem value={0}>Semua</MenuItem>
          <MenuItem value={1}>Open</MenuItem>
          <MenuItem value={2}>Closed</MenuItem>
          <MenuItem value={3}>Partial</MenuItem>
        </Select>
        <button
          className="bg-blue-500 text-white w-1/2 flex items-center justify-between p-[10px] rounded-[12px] text-[14px] hover:bg-gray-300"
          type="button"
          onClick={() => navigate("/purchase-order/create-po")}
        >
          Buat PO
          <IoMdAddCircleOutline />
        </button>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal PO
                </th>
                <th className="p-2 text-center whitespace-nowrap">Nomor PO</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Distributor
                </th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Total</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No Invoice
                </th>
                <th className="p-2 text-center whitespace-nowrap">Action</th>
              </thead>
              <tbody>
                {dataList && !isLoading && dataList.data ? (
                  dataList.data.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">1</td>
                      <td className="p-2 text-center">
                        {moment(item.po_date).format("DD/MM/YYYY")}
                      </td>
                      <td
                        className="p-2 text-center text-blue-500 cursor-pointer hover:text-gray-300"
                        onClick={() =>
                          navigate(`/purchase-order/detail-po/${item.id}`)
                        }
                      >
                        {item.po_number}
                      </td>
                      <td className="p-2 text-center">
                        {item.distributor_name}
                      </td>
                      <td className="p-2 text-left">
                        <div className="w-full flex justify-between gap-4 items-center">
                          <p>{item.product_name.slice(0)}</p>
                          {item.product_name.length > 1 ? (
                            <p
                              className="text-blue-500 cursor-pointer"
                              onMouseEnter={(e) => {
                                setMoreProductArr(item.product_name.slice(1));
                                setAnchorElMoreProduct(e.currentTarget);
                              }}
                              onMouseLeave={() => setAnchorElMoreProduct(null)}
                            >
                              +{item.product_name.length - 1} Lainnya
                            </p>
                          ) : null}
                        </div>
                      </td>
                      <td
                        className={`p-2 text-center font-bold ${
                          true ? "text-green-500" : "text-orange-500"
                        }`}
                      >
                        {"Open"}
                      </td>
                      <td className="p-2 text-center">
                        {item.total.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </td>
                      <td className="p-2 text-center">
                        {item.get_purchase_order_billing_lists?.length > 0
                          ? item.get_purchase_order_billing_lists.map((trx) => (
                              <div
                                className=" text-blue-500 cursor-pointer hover:text-gray-300"
                                onClick={() =>
                                  navigate(
                                    `/purchase-order/detail-invoice/${trx.billing_id}`
                                  )
                                }
                              >
                                {trx.invoice}
                              </div>
                            ))
                          : "-"}
                      </td>
                      <td className="p-2 text-center">
                        <div className="w-full flex gap-2 items-center">
                          <button
                            className="bg-gray-100 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:bg-gray-300 disabled:text-white"
                            type="button"
                          >
                            Print
                          </button>
                          <button
                            className="bg-red-100 border-red-500 text-red-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                            disabled={
                              !item.get_purchase_order_billing_lists ||
                              item.get_purchase_order_billing_lists?.length ===
                                0
                            }
                            onClick={() => {
                              setSelectedPo(item);
                              setOpenDelete(true);
                            }}
                            type="button"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td className="p-2 text-center" colSpan={9}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada data"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && !isLoading && dataList.total > 0 ? (
        <PagePagination
          offset={reqBody.offset}
          setOffset={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: e,
            }));
          }}
          limit={reqBody.limit}
          setLimit={(e) => {
            setReqBody((prevValue) => ({
              ...prevValue,
              limit: e,
            }));
          }}
          total={dataList.total}
        />
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
      />

      <ModalDeletePo
        open={openDelete}
        setOpen={setOpenDelete}
        po={selectedPo}
        setPo={setSelectedPo}
      />

      <Popper
        placement="bottom"
        open={Boolean(anchorElMoreProduct)}
        anchorEl={anchorElMoreProduct}
        sx={{ maxWidth: "200px" }}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            // options: {
            //   element: arrowRef,
            // },
          },
        ]}
      >
        <div className="bg-white text-[10px] p-2 rounded-md border text-wrap whitespace-wrap overflow-hidden break-words">
          {moreProductArr.map((item, i) => `${i + 1}. ${item}`).join(", ")}
        </div>
      </Popper>
    </div>
  );
};

export default PurchaseOrderList;
